<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование производителя</div>
                <div class="page__desc">Измените данные производителя</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="manufacturer !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="manufacturer.title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="country">Страна</label>
                <div class="control-group">
                    <input
                            v-model="manufacturer.country"
                            id="country"
                            type="text"
                            name="country"
                            class="form-control" >
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogManufacturerUpdate',
        computed: {
            manufacturer() {
                return this.$store.state.manufacturers.manufacturer
            }
        },
        methods: {
            ...mapActions([
                'catalogManufacturersGet',
                'catalogManufacturersUpdate',
            ]),
            async getManufacturer() {
                await this.catalogManufacturersGet({
                    id: this.$route.params.id,
                })
            },
            async update() {
                const formData = new FormData();
                formData.append("id", this.manufacturer.id);
                formData.append("title", this.manufacturer.title);
                formData.append("country",this.manufacturer.country);

                await this.catalogManufacturersUpdate(formData)
            }
        },
        created() {
            this.getManufacturer();
        },
    }
</script>